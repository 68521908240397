const website = require('./website')

module.exports = {
  "de-de": {
    default: true,
    path: "de",
    locale: "de-de",
    siteLanguage: "de",
    ogLang: "de_DE",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: "Kategorie",
    categories: "Kategorien",
    was: "wurde",
    were: "wurden",
    tagged: "markiert mit",
    recent: "Neue",
    projects: "Projekte",
    allCategories: "Alle Kategorien",
    entries: "Einträge",
    readMore: "Weiterlesen",
    published: "Veröffentlicht",
  }/*,
  "en-gb": {
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_US",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: "Category",
    categories: "Categories",
    was: "was",
    were: "were",
    tagged: "tagged with",
    recent: "Recent",
    projects: "projects",
    allCategories: "All categories",
    entries: "entries",
    readMore: "read more",
    published: "Published",
  }*/
}
